<template>
  <Container>
    <Headline :level="headlineLevel">{{ title }}</Headline>
    <ul class="documents">
      <li v-for="element in element.nestedElements" :key="element.id">
        <AlchemyDocumentLink :element="element" />
      </li>
    </ul>
  </Container>
</template>

<script>
  import AlchemyDocumentLink from "./document_link.vue"
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    components: {
      AlchemyDocumentLink,
    },
    mixins: [AlchemyElement],
    computed: {
      title() {
        return this.getValue("title") || this.getValue("headline")
      },
      headlineLevel() {
        return this.getIngredient("headline")?.level || 3
      },
    },
  }
</script>

<style lang="scss" scoped>
  .documents {
    display: flex;
    flex-wrap: wrap;
    padding: 0 $space-s;
    margin: $space-m -2 * $space-s;

    li {
      width: calc(33.333% - #{2 * $space-s});
      list-style-type: none;
      padding: 0 $space-s;

      @include viewport("sm") {
        width: calc(50% - #{2 * $space-s});
      }

      @include viewport("mini") {
        width: calc(100% - #{2 * $space-s});
      }

      .document-link {
        width: 100%;
      }
    }
  }
</style>
